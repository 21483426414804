export interface DataFilter {
	type: DataType;
	key?:
		| "_country"
		| "_state"
		| "_municipalities"
		| "_neighborhood"
		| "_poisCategory"
		| "_poisSubCategory"
		| "_poisMarca"
		| "_poisName"
		| "_likeName";
	value?: string[] | number[];
}

export interface PostMessageEvent {
	type: DataType;
	data: {
		_country?: number[];
		_state?: string[];
		_municipalities?: string[];
		_neighborhood?: string[];
		_poisCategory?: string[];
		_poisSubCategory?: string[];
		_poisMarca?: string[];
		_poisName?: string[];
	};
	sync_status?: boolean;
}

export enum DataType {
	SUBMIT = "SUBMIT",
	UPDATE = "UPDATE",
	SYNC_STATUS = "SYNC_STATUS",
	RUN_SYNC = "RUN_SYNC",
	URL_ORIGIN = "URL_ORIGIN",
}

export interface PasingFilter {
	_country?: string[];
	_state?: string[];
	_municipalities?: string[];
	_neighborhood?: string[];
	_poisCategory?: string[];
	_poisSubCategory?: string[];
	_poisMarca?: string[];
	_poisName?: string[];
}
