import Vue from "vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import { ElementData } from "@/interfaces/persons/v10/person";
import ChipFilterGroup from "@/views/Admin/Persons/V10/Dependencies/Common/Chips/Filters/index.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardChip from "@/views/Admin/Persons/V10/Dependencies/Common/Chips/Card/index.vue";
import {
	SearchFilterEntity,
	TYPE_FILTER,
} from "@/models/persons/v10/Implements";
import { PersonKey } from "@/interfaces/persons/v10/types";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "FilterChips",

	props: {
		type: {
			type: String,
			default: "",
		},
		show: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "",
		},
		selected: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	components: {
		DialogDefault,
		ChipFilterGroup,
		CardTextField,
		CardChip,
	},

	data: () => ({
		search: new SearchFilterEntity(),
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		...mapGetters("person", ["getQueryParams"]),
		getCountryCode() {
			return this.getQueryParams.country_code;
		},
		getFilterByType() {
			return TYPE_FILTER[this.type];
		},
		getTooltipText() {
			let message = `persons.v10.panelsPois.subPanel.${this.type}.clear-filters`;
			return this.$t(message);
		},
	},

	methods: {
		updateTerm(event: { type: string; key: string; value: string }) {
			this.search.setData(event.type, event.key, event.value);
		},
		handleAction(event: any) {
			this.$emit(event.type);
		},
		getFilterByKey(type: string) {
			const _type =
				this.type === PersonKey.OOH ? type.replace("ooh_", "") : type;
			return (this.selected as ElementData[]).filter(
				(s) => s.type === _type
			);
		},
		getTitle(key: string) {
			const title =
				this.type == "geo"
					? `persons.v10.panelsPois.${this.type}.${this.getCountryCode}.${key}`
					: `persons.v10.panelsPois.subPanel.${this.type}.${key}`;
			return this.$t(title);
		},
		handleClearFilters() {
			this.$emit("clear-all");
			this.$emit("close");
		},
		handleRemove(event: { item: ElementData }) {
			this.$emit("remove", event);
		},
	},

	watch: {},
});
