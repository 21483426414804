import { ElementData } from "@/interfaces/persons/v10/person";
import { PersonKey } from "@/interfaces/persons/v10/types";
import FilterCheckboxSkeleton from "@/components/Commons/Skeletons/FilterCheckbox/index.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { LIMIT } from "@/interfaces/persons/v10/query/global";

export default Vue.extend({
	name: "SelectRadioStrategy",
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { FilterCheckboxSkeleton },
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("person", ["getPerson", "getQueryParams", "getResource"]),
		selectedData: {
			get(): ElementData {
				const selectedData: ElementData = this.getPerson?.getByKey(
					PersonKey.STRATEGY
				);
				return {
					id: selectedData.id,
					value: selectedData.value,
				};
			},
			set(val: ElementData) {
				this.getPerson.setByKey(PersonKey.STRATEGY, val);
			},
		},
		getStrategies() {
			return this.getResource({ key: "strategies" });
		},
		getSkeletonLength() {
			return LIMIT;
		},
	},
	methods: {
		...mapActions("person", ["fetchData", "setResource"]),
		isChecked(item: ElementData) {
			return this.selectedData.id === item.id;
		},
	},
	watch: {
		// isActive(val, old) {
		// 	console.log(`${this.$options.name}::isActive`, { val, old });
		// },
	},
});
