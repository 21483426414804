import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { debounce, isEmpty, isEqual, isUndefined } from "lodash";

import { ResultElementData } from "@/interfaces/paginated";
import { ElementData } from "@/interfaces/persons/v10/person";
import {
	PersonFilterType,
	PersonKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPrivateKey,
	PersonUsesCasesKey,
	TotalType,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import {
	ExpansionItem,
	ExpansionItemEntity,
	ResultType,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import { resolveResult } from "@/services/persons/v10/person-service";
import { PersonEntity } from "@/models/persons/v10/Person";
import { FILTER_KEY_MAPPING } from "@/models/persons/v10/Implements";

import {
	DELAY_FETCH,
	HIDE_VIEW_CLEAR_FILTERS,
} from "@/models/persons/v10/DataDefault";
import { QueryParamEntity } from "@/models/persons/v10/Query";
import { PostDataEntity } from "@/models/persons/v10/Query/Pois";
import { buildDataParams } from "./utils";
import { AxiosError } from "axios";

export default Vue.extend({
	name: "MixinSelectFilter",
	data: function () {
		return {
			config: {
				type: "", // geo|pois|ooh|pos
				key: "", // filters
			},
		};
	},
	created: function () {
		this.$nextTick(async () => {});
	},
	mounted() {},

	computed: {
		...mapGetters("person", [
			"getPerson",
			"getPostData",
			"getQueryParams",
			"getLikeAll",
		]),

		getExpansionItems(): ExpansionItemEntity[] {
			return this.expansionItems;
		},

		isParamsGet(): Boolean {
			return [PersonKey.GEO].includes(this.config.type);
		},

		queryParams: {
			get(): QueryParamEntity | PostDataEntity {
				return this.isParamsGet
					? this.getQueryParams
					: this.getPostData;
			},
			set(value) {},
		},
	},

	methods: {
		...mapActions("person", ["fetchPostPaginated", "fetchGetPaginated"]),

		async __run(key: PersonFilterType, type: ResultType) {
			const person: PersonEntity = this.getPerson;
			const isFetching = person.fetching_filter.isFetching(key);
			if (!isFetching) {
				this.fetchSaveResultItem(key, type);
			}
		},

		/**
		 * Fetch All Item Resource
		 */
		async fetchExpansionItems() {
			const person: PersonEntity = this.getPerson;
			const canFetch: Boolean = person.hasStrategyAndUseCases();

			if (!canFetch) return;

			person.fetching_filter.setFetchingAll();

			const keysToFetch: PersonFilterType[] =
				FILTER_KEY_MAPPING[this.config.type];

			if (keysToFetch) {
				keysToFetch.forEach((key) => {
					this.fetchSaveResultItem(key, ResultType.FILTERING);
				});
			}
		},

		/**
		 * Find expansion item by key
		 * @param key
		 * @returns
		 */
		findExpansionItem(key: PersonFilterType) {
			const expansionItems: ExpansionItemEntity[] = this.expansionItems;
			return expansionItems.find((e) => e.key === key);
		},

		/**
		 * Fetches a specific item resource based on the provided key.
		 *
		 * @param key The key identifying the item resource.
		 * @returns A promise resolving to the fetched item or undefined in case of an error.
		 */
		async fetchItemByKey(
			key: PersonFilterType
		): Promise<ResultElementData | undefined> {
			try {
				const person: PersonEntity = this.getPerson;

				const isGet: Boolean = this.isParamsGet;

				const dataParams = buildDataParams(
					this.config.type,
					person,
					key,
					isGet
				);

				const fetchMethod = isGet
					? this.fetchGetPaginated
					: this.fetchPostPaginated;
				const result: ResultElementData = await fetchMethod(dataParams);

				return {
					...result,
					data: await resolveResult(key, result),
				};
			} catch (errors) {
				const typedError = errors as AxiosError;
				const isCancel: boolean = (errors as any)?.isCancel;
				if (isCancel) return;
				console.error(
					`${this.$options.name}::fetchItemByKey`,
					typedError
				);
			}
		},

		/**
		 * Fetch Item & Save Result
		 * @param key
		 * @returns
		 */
		async fetchSaveResultItem(
			key: PersonFilterType,
			resultType: ResultType
		) {
			const person: PersonEntity = this.getPerson;
			const canFetch: Boolean = person.hasStrategyAndUseCases();

			if (!canFetch) return;

			let item: ExpansionItemEntity = this.findExpansionItem(key);

			if (isUndefined(item)) return;

			item.setLoading(true);

			const promResult: Promise<ResultElementData | undefined> =
				this.fetchItemByKey(key);

			promResult
				.then(async (_result: ResultElementData | undefined) => {
					if (isUndefined(_result)) return;

					switch (resultType) {
						case ResultType.NEW:
							await item.setNewToResult(_result);
							break;

						case ResultType.SEARCH:
							await item.setNewToResult(_result);
							break;

						case ResultType.LOAD_MORE:
							if (this.queryParams.isFetchingMaxPage(item.key)) {
								await item.setNewToResult(_result);
							} else {
								await item.loadMoreToResult(_result);
							}
							break;

						case ResultType.FILTERING:
							await item.setNewToResult(_result);
							break;
					}

					await this.validateLikeAll(item, resultType);

					item.setLoading(false);
					person.fetching_filter.setFetching(key, false);
					this.queryParams.setFetchingMaxPage(item.key, false);
				})
				.catch((_error: any) => {
					item.setLoading(false);
					person.fetching_filter.setFetching(key, false);
					this.queryParams.setFetchingMaxPage(item.key, false);
					console.error(
						`${this.$options.name}::fetchSaveResultItem`,
						{
							_error,
						}
					);
				});
		},

		async validateLikeAll(
			item: ExpansionItemEntity,
			resultType: ResultType
		) {
			let person: PersonEntity = this.getPerson;

			let isSelectedAll: Boolean =
				person.select_all[item.type][item.key].total.checked;

			let selectedData: ElementData[] =
				person[this.config.type]?.selected[item.key] || [];

			let isSelectedShown: Boolean =
				person.select_all[item.type][item.key].shown.checked;

			if (isSelectedShown) {
				if (item.resultData.data.length !== selectedData.length) {
					person.select_all.clearChecked(
						item.type as any,
						item.key as any
					);
				}
			}

			if (isSelectedAll && resultType === ResultType.LOAD_MORE) {
				await this.toggleSelectAll(item, isSelectedAll);
			}
		},

		async toggleSelectAll(item: ExpansionItemEntity, all: Boolean) {
			const items: ElementData[] = all ? item.resultData.data : [];
			await this.getPerson.toggleSelectAll(
				item.type,
				TypeFilterKey.SELECTED,
				item.key,
				items
			);
		},

		async handleUpdate(params: {
			key: any;
			item: any;
			ariaChecked: Boolean;
		}) {
			let { key, item, ariaChecked } = params;
			await this.resetCurrentPage(false);

			await this.getPerson.updateFilter(
				this.config.type,
				this.config.key,
				key,
				item
			);

			let _item: ExpansionItemEntity = this.findExpansionItem(key);

			const omitKeys: PersonFilterType[] =
				HIDE_VIEW_CLEAR_FILTERS as PersonFilterType[];

			if (!omitKeys.includes(key)) {
				await this.getPerson.togglePreFilter({
					key: this.config.type,
					filter: key,
					toggle: ariaChecked,
					element: item,
				});
			} else if (!ariaChecked) {
				/**
				 * si el aria {ariaChecked} es false actualizar el checked de total -> {select_all}
				 */
				this.getPerson.select_all.clearCheck(
					this.config.type,
					_item.key,
					TotalType.TOTAL
				);
			}
		},

		async handleSearch(params: {
			action: ResultType;
			key: PersonFilterType;
			current_page: number;
		}) {
			let { key } = params;
			await this.resetCurrentPage(false);
			this.fetchSaveResultItem(key, params.action);
			this.queryParams.setSearching(key, false);
		},

		async handleLoadMore(params: {
			key: PersonFilterType;
			current_page: number;
		}) {
			let { key } = params;
			await this.fetchSaveResultItem(key, ResultType.LOAD_MORE);
			this.queryParams.setSearching(key, false);
		},

		async filterData(
			key: PersonPoisKey | PersonPoisKey | PersonPrivateKey | PersonOohKey
		): Promise<ElementData[]> {
			return this.getPerson[this.config.type]?.selected[key] || [];
		},

		async compareElements(elements: ElementData[], compare: ElementData[]) {
			return elements.every((e) =>
				compare.some((c) => c.identifier === e.identifier)
			);
		},

		async verifySelectedInResult(key: string) {
			let _item: ExpansionItemEntity = this.findExpansionItem(key);

			if (!_item.key) return;

			const elements: ElementData[] = _item.resultData.data;

			const selectedData: ElementData[] = await this.filterData(key);

			const resultIncludesSelected: Boolean = await this.compareElements(
				elements,
				selectedData
			);

			this.getPerson.select_all.updateChecked(
				this.config.type,
				_item.key,
				TotalType.SHOWN,
				resultIncludesSelected && !isEmpty(elements)
			);
		},

		async resetCurrentPage(isFetchingMaxPage: Boolean = false) {
			const total: number = this.getExpansionItems.length;
			for (let index = 0; index < total; index++) {
				const expansionItem: ExpansionItem =
					this.getExpansionItems[index];

				expansionItem.setCurrentPage(1);
				this.queryParams.setCurrentPage(expansionItem.key, 1);

				this.queryParams.setFetchingMaxPage(
					expansionItem.key,
					isFetchingMaxPage
				);
			}
		},

		updateFetchFilters(filters: PersonFilterType[]) {
			filters.map((filter) => {
				this.queryParams.resetAllPage(filter, 1);
				this.__run(filter, ResultType.FILTERING);
			});
		},

		reFetchPaginate(type: PersonKey) {
			if (type === PersonKey.POIS) {
				const hasElement = this.getPerson.hasElementData(
					PersonKey.GEO,
					TypeFilterKey.PRE
				);
				if (!hasElement) return;
			}

			this.updateFetchFilters(FILTER_KEY_MAPPING[type]);
		},

		isConfigType(type: PersonKey) {
			return this.config.type === type;
		},
	},
	watch: {
		isActive: {
			async handler(val, old) {
				/**
				 * Load the initial data for each item
				 */
				if (val) {
					await this.resetCurrentPage(true);
					await this.fetchExpansionItems();
				}
			},
			immediate: true,
		},

		/**
		 * COUNTRY GLOBAL
		 */
		"getPerson.country_global": {
			handler: debounce(async function (val, oldVal) {
				if (isEqual(val, oldVal)) return;
				await this.fetchExpansionItems();
			}, DELAY_FETCH),
			deep: true,
			immediate: false,
		},
	},
});
