import { ElementData } from "@/interfaces/persons/v10/person";
import { PersonKey } from "@/interfaces/persons/v10/types";
import { PersonEntity } from "@/models/persons/v10/Person";
import FilterCheckboxSkeleton from "@/components/Commons/Skeletons/FilterCheckbox/index.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { LIMIT } from "@/interfaces/persons/v10/query/global";

export default Vue.extend({
	name: "SelectRadioUseCase",
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { FilterCheckboxSkeleton },
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("person", ["getPerson", "getQueryParams", "getResource"]),
		selectedData: {
			get(): ElementData {
				return this.getPerson?.getByKey(PersonKey.USES_CASES);
			},
			set(val: ElementData) {
				this.getPerson.setByKey(PersonKey.USES_CASES, val);
			},
		},
		getItems() {
			let items: ElementData[] = this.getResource({ key: "uses_cases" });
			return items.filter(i => i.types?.includes(this.getStrategyType));
		},
		getStrategyType(): string {
			return this.getPerson.strategy.type || "";
		},
		getPersonComputed(): PersonEntity {
			return this.getPerson;
		},
		getSkeletonLength() {
			return LIMIT;
		},
	},
	methods: {
		...mapActions("person", ["fetchData", "setResource"]),
		isChecked(item: ElementData) {
			return this.selectedData?.id === item.id;
		},
		getTranslate(value: string) {
			return this.$t(`persons.v10.panelsPois.subPanel.uses_cases.${value}`);
		}
	},
	watch: {
		// isActive(val, old) {
		// 	console.log(`${this.$options.name}::isActive`, { val, old });
		// },
	},
});
