var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dialog-default',{attrs:{"persistentDialog":true,"dialog":_vm.show,"btnText":`${_vm.$t('persons.labels.store-attribution.title')}`,"btnClass":"mr-1 mb-1","outlined":true,"maxWidth":850,"isXSmall":true,"activation":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('header',{staticClass:"ml-4 my-4 d-flex align-center"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"ml-4 font-weight-black warning--text"},[_vm._v(" "+_vm._s(_vm.$t("persons.v10.dialogs.map_resync.title"))+" ")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"grey lighten-3 pt-4",attrs:{"elevation":"0","justify":"start","align":"center"}},[_c('v-container',{staticClass:"mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_c('span',{staticClass:"text--lighten-1 body-1 text-pre-line text-break d-block text-left"},[_vm._v(" "+_vm._s(_vm.$t( "persons.v10.dialogs.map_resync.description" ))+" ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-actions',[_c('v-spacer'),_c('CardAction',{attrs:{"showBtns":['run', 'cancel'],"texts":{
									cancel: _vm.$t(
										'persons.v10.dialogs.map_resync.continue_with_dsp'
									),
									run: _vm.$t(
										'persons.v10.dialogs.map_resync.continue_with_map'
									),
								},"disabled":{
									run: false,
									cancel: false,
								},"variant":"primary"},on:{"action":_vm.handleAction}})],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }