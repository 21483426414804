import { DataFilter, DataType, PostMessageEvent } from "@/interfaces/persons/v10/carto";
import { ElementData } from "@/interfaces/persons/v10/person";
import { Mode } from "@/interfaces/persons/v10/query/global";
import { isUrl } from "@/services/rule-services";
import { getHash, sleep } from "@/utils/convert";
import { isEmpty, isNull, isUndefined } from "lodash";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  isNameKey,
  isPrivateKey,
  matchedDSPCartoKeys,
  matchedKeys,
  matchedLikeNames,
  matchUsesCasesWithMap,
  UseCasesMap,
} from "./utils";
import {
  ButtonActionType,
  PersonFilterType,
  PersonGeoKey,
  PersonKey,
  PersonOohKey,
  PersonPoisKey,
  PersonPosKey,
  PersonPrivateKey,
} from "@/interfaces/persons/v10/types";
import { determineFilterKey } from "@/models/persons/v10/Implements";

export default Vue.extend({
  name: "CartoPois",
  model: {},
  props: {
    isSynckedWithCarto: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: "_pois_carto",
    },
    styles: {
      type: Object,
      default: function () {
        return {
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0px auto",
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({
    hash: "",
    isLoaded: false,
    syncAndCalculateReach: false,
  }),
  created() {
    // this.$nextTick(async () => {
    // 	this.addListenerMessage();
    // });
    this.emitInterface();
  },
  beforeDestroy() {},
  async mounted() {
    this.$nextTick(async () => {
      this.hash = await getHash();
      if (!this.hasURL) {
        this.isLoaded = true;
      }
    });
  },
  computed: {
    ...mapGetters("person", ["getPerson"]),
    getUseCase() {
      return this.getPerson.getByKey(PersonKey.USES_CASES);
    },
    hasURL() {
      if (!this.hash) return false;
      return isUrl(process.env.VUE_APP_CARTO_URL);
    },
    getURL() {
      return `${this.getPerson.getEndpoint()}/${this.$i18n.locale || "en"}`;
    },
    getEndpointKey() {
      return this.getPerson.getEndpointKey();
    },
    hasCountry() {
      return this.getPerson.hasCountry();
    },
    getCountry() {
      if (!this.getPerson.hasCountry()) return [];
      return this.getPerson.getCountryCode("id", "array");
    },
  },
  methods: {
    ...mapActions("person", ["fetchDataPaginated", "fetchPostPaginated"]),
    getFilters() {
      return this.getPerson.getFiltersForCarto();
    },

    async addListenerMessage() {
      window.addEventListener("message", this.captureMessage, false);
    },

    async captureMessage(event: MessageEvent) {
      const { origin, data }: { origin: string; data: PostMessageEvent } = event;

      if (origin !== process.env.VUE_APP_CARTO_URL) return;

      if (data.type === DataType.SYNC_STATUS) {
        // Getting Sync Status from Carto Map and update Sync Status on DSP
        this.$emit("mapRequestSync", data.sync_status);
      }

      if (data.type === DataType.UPDATE && !this.syncAndCalculateReach) {
        // Scroll to top
        document.querySelectorAll(".layout-main").forEach(item => item.scrollTo({ top: 0, behavior: "smooth" }));
        await Promise.all(
          Object.entries(data.data).map(([key, value]) => {
            // console.log(`capturando sincronizacion: ${key}`, {
            // 	value,
            // 	isUndefined: isUndefined(value),
            // 	isEmpty: isEmpty(value),
            // });

            if (!isUndefined(value)) {
              return this.updateDataFilters({ key, value });
            }
          }),
        );

        await sleep(500);
        this.$emit("onSync");
      } else if (data.type === DataType.UPDATE && this.syncAndCalculateReach) {
        await Promise.all(
          Object.entries(data.data).map(([key, value]) => {
            // console.log(`capturando sincronizacion: ${key}`, {
            // 	value,
            // 	isUndefined: isUndefined(value),
            // 	isEmpty: isEmpty(value),
            // });

            if (!isUndefined(value)) {
              return this.updateDataFilters({ key, value });
            }
          }),
        );

        await sleep(500);
        this.$emit("forceCalculateReach");
      }
    },

    async updateDataFilters(params: { key: string; value: string[] }) {
      const updateData = {
        key: matchedKeys[params.key],
        value: params.value,
      };

      // Rematch the keys cause geographical names are the same in GEO
      // but is different in stored selecteds on DSP
      if (this.getPerson.isAnalizedPos() && ["states", "cities", "neighborhoods"].includes(updateData.key)) {
        switch (updateData.key) {
          case "states":
            updateData.key = PersonPosKey.CHIPPER_STATES;
            break;
          case "cities":
            updateData.key = PersonPosKey.CHIPPER_CITIES;
            break;
          case "neighborhoods":
            updateData.key = PersonPosKey.CHIPPER_NEIGHBORHOODS;
            break;
        }
      }

      // console.log(`sincronizando filtros: ${params.key}`, {
      // 	value: params.value,
      // 	isUndefined: isUndefined(params.value),
      // 	isEmpty: isEmpty(params.value),
      // });

      // No actualizar si el valor es vacio
      //if (isEmpty(updateData.value)) return;

      let filterKey: PersonKey = determineFilterKey(updateData.key);

      if (filterKey === PersonKey.NONE) return;

      await this.getPerson.setNewFilters(filterKey, updateData.key, updateData.value);
    },

    async initializeCountry() {
      await this.$nextTick();
      const country: number[] = this.getPerson.getCountryCode("id", "array");
      if (isEmpty(country)) return;
      await this.syncFilter("country", country, undefined);
      await this.runSubmit();
    },

    /**
     * Run Submit PostMessage
     */
    async runSubmit() {
      // Sending the current DSP HOST URL to Carto Map for communication
      this.postMessage({
        type: DataType.URL_ORIGIN,
        urlOrigin: window.location.origin,
      });
      return await this.postMessage({ type: "SUBMIT" } as DataFilter);
    },

    /**
     * Run Sync Button on Map
     */
    async runSyncOnMap() {
      //Force DSP to calculate reach inmediately after sync
      this.syncAndCalculateReach = true;

      return await this.postMessage({
        type: DataType.RUN_SYNC, // Trigger Syncronization action on Carto Map
      });
    },

    /**
     * Iframe postMessage
     * @param dataFilter
     */
    async postMessage(dataFilter: DataFilter) {
      const iframe = window.frames[`${this.reference}`];
      return iframe.contentWindow.postMessage(dataFilter, process.env.VUE_APP_CARTO_URL);
    },

    syncFilter(type: string, val: string[] | number[], old?: string[] | number[]) {
      const params = {
        type: "UPDATE",
        key: matchedDSPCartoKeys[type],
        value: val,
      } as DataFilter;

      if (!this.isLoaded) return Promise.resolve(undefined);
      // if (!isPassedWatcherProp(val, old)) return;
      return Promise.resolve(this.postMessage(params as DataFilter));
    },

    async sendAllFilters() {
      await this.$nextTick();

      const country: number[] = this.getCountry;
      await this.syncFilter("country", country, undefined);

      let forcedEmptySync = false;

      if (!this.getPerson.table_id) {
        forcedEmptySync = true;
      }

      await this.sendUseCase();

      // geo
      await this.sendFilter(PersonKey.GEO, PersonGeoKey.STATES, forcedEmptySync);
      await this.sendFilter(PersonKey.GEO, PersonGeoKey.CITIES, forcedEmptySync);
      await this.sendFilter(PersonKey.GEO, PersonGeoKey.NEIGHBORHOODS, forcedEmptySync);

      // pois
      await this.sendFilter(PersonKey.POIS, PersonPoisKey.CATEGORIES, forcedEmptySync);
      await this.sendFilter(PersonKey.POIS, PersonPoisKey.SUBCATEGORIES, forcedEmptySync);
      await this.sendFilter(PersonKey.POIS, PersonPoisKey.BRANDS, forcedEmptySync);
      await this.sendFilter(PersonKey.POIS, PersonPoisKey.NAMES, forcedEmptySync);

      // pos
      if (this.getPerson.isAnalizedPos()) {
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_STATES, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_CITIES, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_NEIGHBORHOODS, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_MACRO_CATEGORIES, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_CATEGORIES, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_COMPANIES, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_BRANDS, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_NAMES_SKU, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_STORES_TYPE, forcedEmptySync);
        await this.sendFilter(PersonKey.POS, PersonPosKey.CHIPPER_QUINTILS, forcedEmptySync);
      }

      // private
      await this.sendFilter(PersonKey.PRIVATE, PersonPrivateKey.PRIVATES, forcedEmptySync);

      // ooh
      await this.sendFilter(PersonKey.OOH, PersonOohKey.OOH_CATEGORIES, forcedEmptySync);
      await this.sendFilter(PersonKey.OOH, PersonOohKey.OOH_SUBCATEGORIES);
      await this.sendFilter(PersonKey.OOH, PersonOohKey.OOH_BRANDS, forcedEmptySync);
      await this.sendFilter(PersonKey.OOH, PersonOohKey.OOH_NAMES, forcedEmptySync);

      // table_name
      await this.sendTableName(forcedEmptySync);

      setTimeout(() => {
        this.runSubmit();
      }, 500);
    },

    async sendTableName(force_undefined = false) {
      const table_id = !this.getPerson.isAnalizedGeo() && !force_undefined ? this.getPerson.table_id : undefined;

      await this.syncFilter("tableId", table_id, undefined);
    },

    async handleLoad() {
      this.isLoaded = true;
      await sleep(100);
      await this.sendAllFilters();
      await this.addListenerMessage();
    },

    async sendFilter(type: PersonKey, key: PersonFilterType, force_empty: boolean) {
      const elements: ElementData[] = this.getPerson[type].selected[key];
      const filters: (string | number)[] = force_empty
        ? []
        : elements.map(f => {
            if (isPrivateKey(key)) return f.id;
            return f.value;
          });

      // ooh and pois search verification
      if (isNameKey(key)) {
        const { term, checked } = this.getPerson.select_all[type][key].total;

        this.syncFilter(matchedLikeNames[key], checked ? [term] : []);
      }

      await this.syncFilter(key, filters);
    },

    async sendUseCase() {
      const useCaseKey = this.getPerson.getByKey(PersonKey.USES_CASES).key;
      await this.syncFilter("useCase", matchUsesCasesWithMap[useCaseKey] ?? UseCasesMap.IDLE);
    },

    // Exposing Interface to Parent Component
    // In this case, Tabs component can execute the runSyncOnMap method from here
    emitInterface() {
      this.$emit("interface", {
        runSyncOnMap: () => this.runSyncOnMap(),
      });
    },
  },
  watch: {
    async "getPerson.table_id"(val: any) {
      if (isNull(val)) {
        this.sendAllFilters();
      }
    },
  },
});
