import {
  PersonFilterType,
  PersonOohKey,
  PersonPoisKey,
  PersonPosKey,
  PersonPrivateKey,
  PersonUsesCasesKey,
} from "@/interfaces/persons/v10/types";
import { isEmpty, isEqual } from "lodash";

export const matchedKeys = {
  _poisCategory: "categories",
  _poisSubCategory: "subcategories",
  _poisMarca: "brands",
  _poisName: "names",
  _state: "states",
  _municipalities: "cities",
  _neighborhood: "neighborhoods",
  _likeNames: "likeNames",
  _oohCategories: "ooh_categories",
  _oohSubcategories: "ooh_subcategories",
  _oohBrands: "ooh_brands",
  _oohNames: "ooh_names",
  _likeOohNames: "likeOohNames",
  _privatePois: "privates",
  _posMacroCategories: PersonPosKey.CHIPPER_MACRO_CATEGORIES,
  _posCategories: PersonPosKey.CHIPPER_CATEGORIES,
  _posCompanies: PersonPosKey.CHIPPER_COMPANIES,
  _posBrand: PersonPosKey.CHIPPER_BRANDS,
  _posBrandSku: PersonPosKey.CHIPPER_NAMES_SKU,
  _posStoreTypes: PersonPosKey.CHIPPER_STORES_TYPE,
  _posQuintils: PersonPosKey.CHIPPER_QUINTILS,
};

export const matchedLikeNames = {
  names: "likeNames",
  ooh_names: "likeOohNames",
};

export enum UseCasesMap {
  IDLE = "IDLE",
  GEO = "GEO",
  POIS = "POIS",
  POS = "POS",
}

export const matchUsesCasesWithMap: Record<PersonUsesCasesKey, UseCasesMap> = {
  individuals_seen_in_certain_area: UseCasesMap.GEO,
  individuals_seen_close_to_a_poi_ooh: UseCasesMap.POIS,
  pois_close_to_certain_oohs: UseCasesMap.POIS,
  store_attribution_report: UseCasesMap.POIS,
  users_identified_in_areas_based_pos_point_of_sale_data: UseCasesMap.POS,
  "": UseCasesMap.IDLE,
};

export const matchedDSPCartoKeys = {
  useCase: "_useCase",
  country: "_country",
  categories: "_poisCategory",
  subcategories: "_poisSubCategory",
  brands: "_poisMarca",
  names: "_poisName",
  states: "_state",
  cities: "_municipalities",
  neighborhoods: "_neighborhood",
  likeNames: "_likeNames",
  privates: "_privatePois",
  ooh_categories: "_oohCategories",
  ooh_subcategories: "_oohSubcategories",
  ooh_brands: "_oohBrands",
  ooh_names: "_oohNames",
  likeOohNames: "_likeOohNames",
  tableId: "_tableId",
  [PersonPosKey.CHIPPER_STATES]: "_state",
  [PersonPosKey.CHIPPER_CITIES]: "_municipalities",
  [PersonPosKey.CHIPPER_NEIGHBORHOODS]: "_neighborhood",
  [PersonPosKey.CHIPPER_MACRO_CATEGORIES]: "_posMacroCategories",
  [PersonPosKey.CHIPPER_CATEGORIES]: "_posCategories",
  [PersonPosKey.CHIPPER_COMPANIES]: "_posCompanies",
  [PersonPosKey.CHIPPER_BRANDS]: "_posBrand",
  [PersonPosKey.CHIPPER_NAMES_SKU]: "_posBrandSku",
  [PersonPosKey.CHIPPER_STORES_TYPE]: "_posStoreTypes",
  [PersonPosKey.CHIPPER_QUINTILS]: "_posQuintils",
};

export function isValidWatcherProp(val: string[] | string) {
  return val !== "" && !isEmpty(val);
}

export function isEqualWatcherProp(val: string[] | string, old: string[] | string) {
  return isEqual(val, old);
}

export function isPassedWatcherProp(val: string[] | string, old: string[] | string) {
  return isValidWatcherProp(val) && !isEqualWatcherProp(val, old);
}

export function isPrivateKey(key: PersonFilterType): boolean {
  return key === PersonPrivateKey.PRIVATES;
}

export function isNameKey(key: PersonFilterType): boolean {
  return [PersonPoisKey.NAMES, PersonOohKey.OOH_NAMES].includes(key as any);
}
