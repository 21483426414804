import Vue from "vue";

import ExpansionItem from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ExpansionItem/index.vue";
import {
	ExpansionItemEntity,
	getPosExpansions,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonKey,
	PersonPosKey,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import SelectFilter from "../../Mixins/SelectFilter";
import { debounce } from "lodash";
import { DELAY_FETCH } from "@/models/persons/v10/DataDefault";
import { getFilterSlice } from "@/models/persons/v10/Implements";

export default Vue.extend({
	name: "SelectPOS",
	mixins: [SelectFilter],
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { ExpansionItem },
	data: () => ({
		expansionItems: [] as ExpansionItemEntity[],
		config: {
			type: PersonKey.POS,
			key: TypeFilterKey.SELECTED,
		},
	}),
	mounted() {},
	created() {
		this.$nextTick(async () => {
			/**
			 * Load data structure
			 */
			this.expansionItems = await getPosExpansions();

			/**
			 * Load the initial data for each item
			 */
			this.fetchExpansionItems();
		});
	},
	methods: {},
	watch: {
		/**
		 * POS pre
		 */
		"getPerson.pos.pre.chipper_states": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_STATES
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.chipper_cities": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_CITIES
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.chipper_neighborhoods": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_NEIGHBORHOODS
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.macro": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_MACRO_CATEGORIES
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.category": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_CATEGORIES
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.company": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_COMPANIES
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.brand": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(PersonKey.POS, PersonPosKey.CHIPPER_BRANDS)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.name_sku": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_NAMES_SKU
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},
		"getPerson.pos.pre.store_type": {
			handler: debounce(function (val, oldVal) {
				this.updateFetchFilters(
					getFilterSlice(
						PersonKey.POS,
						PersonPosKey.CHIPPER_STORES_TYPE
					)
				);
			}, DELAY_FETCH),
			deep: true,
		},

		/**
		 * GEO pre
		 */
		"getPerson.pos.selected.chipper_states"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_STATES);
		},
		"getPerson.pos.selected.chipper_cities"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_CITIES);
		},
		"getPerson.pos.selected.chipper_neighborhoods"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_NEIGHBORHOODS);
		},
		"getPerson.pos.selected.macro"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_MACRO_CATEGORIES);
		},
		"getPerson.pos.selected.category"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_CATEGORIES);
		},
		"getPerson.pos.selected.company"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_COMPANIES);
		},
		"getPerson.pos.selected.brand"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_BRANDS);
		},
		"getPerson.pos.selected.name_sku"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_NAMES_SKU);
		},
		"getPerson.pos.selected.store_type"(val) {
			this.verifySelectedInResult(PersonPosKey.CHIPPER_STORES_TYPE);
		},
	},
});
