import Vue from "vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import Alertize from "@/components/Alertize.vue";
//@ts-ignore
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { mapGetters } from "vuex";
import { isRequired } from "@/services/rule-services";

export default Vue.extend({
	name: "MapUnsavedFilters",

	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		CardTextField,
		Alertize,
		DialogDefault,
		CardAction,
	},

	data: () => ({
		name: "",
		country_name: "",
		rule_name: [],
		valid: true,
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		...mapGetters("person", ["getPersistent"]),

		getCountryName() {
			return this.getPersistent.country_global.value;
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getRules() {
			return {
				isRequired,
			};
		},
	},

	methods: {
		aplyRules() {
			this.rule_name = [this.getRules.isRequired];
		},

		getErrorMessage(index: any) {
			return getError(this.getErrors, index);
		},

		clearRules() {
			this.rule_name = [];
		},

		handleAction(data: { type: "run" | "cancel" }) {
			if (data.type === "run") {
				// Run sync process with map and then calculate reach
				console.log("Synchronizing...");
				this.$emit("mapSyncRequested", true);
			}
			if (data.type === "cancel") {
				// Calculate reach with DSP Filters
				console.log("Calculating reach with DSP Filters...");
				this.$emit("mapSyncRequested", false);
			}
		},

		reset() {
			this.name = "";
			this.country_name = "";
			this.clearRules();
		},

		closeModal() {
			this.clearRules();
			this.$emit("close");
		},

		async handleSubmit() {
			try {
				await this.aplyRules();

				if (!(await this.validate())) return;

				let params = {
					name: this.name,
				};

				this.$emit("submit", params);

				this.reset();
			} catch (error) {
				this.clearRules();
			}
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
	},

	watch: {},
});
